body {
    /* Set Font */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

#legacy-container select:required:invalid {
    color: #909295;
    font-weight: bold;
}

#legacy-container option[disabled] {
    display: none;
}

#legacy-container option {
    color: black;
}

#legacy-container {
    flex: 1%;
    padding-top: 3%;
    padding-bottom: 2%;

    border-radius: 1vw;
    background-color: #fff;
    border: 0.1vw solid #909295;

    box-shadow: 0vw 0.3vw 0.5vw #909295;

    /* Auto Adjust Width */
    width: 50%;
    height: 100%;

    /* Center Container Box */
    margin-left: auto;
    margin-right: auto;
    display: block;
}

#legacy-container #logo {
    width: 30%;
    height: auto;
}

#legacy-container .central {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#legacy-container .right {
    display: block;
    margin-right: 15%;

    text-align: right;
}

#legacy-container .error {
    color: red;
    margin-bottom: 0;
}

#legacy-container .spacing-smll {
    margin: 3%;
}

#legacy-container .spacing {
    margin: 10%;
}

#legacy-container .box {
    border: 0.15vw solid #909295;
    border-radius: 5px;
}

#legacy-container .row {
    display: flex;
    justify-content: space-between;
}

#legacy-container .inputView {
    padding: 0.5vw;

    font-size: medium;
    font-family: sans-serif;
}


#legacy-container .TextInput {
    margin: 0.5vw;
    text-align: center;
}

#legacy-container .AreaInput {
    resize: none;

    width: 50%;
    padding: 1%;
    padding-bottom: 5%;

    font-size: medium;
    border-radius: 0;
    outline: 0.1vw solid #909295;
    font-family: sans-serif;
}

#legacy-container .btn {
    border-radius: 5%;

    align-items: center;
    justify-content: center;

    margin-top: 1.5vw;
    padding: 15px 25px;
}

#legacy-container .dropDown {
    padding: 0.5vw;
    width: 30%;

    font-size: small;
}

#legacy-container .calendar {
    font-size: small;
}

#legacy-container .DropText {
    margin-top: 1vw;
    margin-bottom: 0.5vw;
}

#legacy-container .entryInput {
    padding-left: 0.5vw;
    width: 27%;
}

#legacy-container .container-header {
    text-align: center;
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

#legacy-container .legacy-lg-btn {
    background-color: #29435A;
    color: #fff;

    font-weight: bold;
    font-size: large;
    text-align: center;
}

#legacy-container .legacy-lg-btn:hover { background-color: #243a4e; }

#legacy-container .loader {
    border: 0.8vw solid #ddd;
    border-radius: 50%;
    border-top: 0.8vw solid #29435A;

    width: 4vw;
    height: 4vw;
    margin-top: 3vh;

    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Mobile Support */
@media only screen and (max-width: 900px) {
    #legacy-container {
        width: 100%;
    }

    #legacy-container #logo {
        width: 60%;
    }

    #legacy-container .dropDown {
        width: 40%;
    }

    #legacy-container .entryInput {
        width: 35%;
    }
}
